import React from "react";

//assets
import XLogo from "../assets/x.svg";
import LinkedInLogo from "../assets/linkedin.svg";

export default function Contact() {
  return (
    <div className="flex justify-center items-center min-h-[95vh] bg-primary-200 px-4 pt-12 pb-6 sm:px-[5%] lg:px-[10%] w-full">
      <div className='flex flex-col justify-center sm:flex-row gap-4 w-full'>
      <div className="flex flex-col grow border-2 border-primary-100 max-h-[470px] max-w-[700px] w-full sm:w-auto bg-white rounded-lg sm:rounded-xl shadow-xl py-6 px-8">
        <h1>Get in touch</h1>
        <form className="flex flex-col">
          <label htmlFor="name" className="mt-4 text-gray-700">Name</label>
          <input
            type="text"
            id="name"
            name="name"
            className="mt-1 w-full py-2 px-3 rounded-md border-2 border-primary-100 shadow-md focus:border-primary-100 focus:ring-primary-100 focus:outline-none"
            placeholder="You"
          />
          <label htmlFor="email" className="mt-4 text-gray-700">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            className="mt-1 w-full py-2 px-3 rounded-md border-2 border-primary-100 shadow-md focus:border-primary-100 focus:ring-primary-100 focus:outline-none"
            placeholder="your@email.com"
          />
          <label htmlFor="message" className="mt-4 text-gray-700">Message</label>
          <textarea
            id="message"
            name="message"
            rows="4"
            className="mt-1 w-full py-2 px-3 rounded-md border-2 border-primary-100 shadow-md focus:border-primary-100 focus:ring-primary-100 focus:outline-none"
            placeholder="Your message here..."
          ></textarea>
                    
          <button type="submit" className="inline-flex justify-center mt-4 py-2 border-2 border-primary-100 shadow-md rounded-md text-primary-100 bg-primary-200 hover:bg-white transition focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-100">
            <h2>Send Message</h2>
          </button>
        </form>
      </div>

      <div className="flex flex-col max-h-[470px] gap-4 w-full sm:w-3/5 sm:max-w-[400px]">
        
        <div className="flex grow justify-between border-2 border-primary-100 w-full bg-white rounded-lg sm:rounded-xl shadow-xl py-6 px-8">
          <div>
            <h2 className="text-xl font-medium cursor-pointer">Investors</h2>
            <h2 className="text-xl font-medium cursor-pointer">Learn More</h2>
            <h2 className="text-xl font-medium cursor-pointer">Contact us</h2>
          </div>
          <div>
            <h2 className="text-xl font-medium cursor-pointer">Public Deck</h2>
            <h2 className="text-xl font-medium cursor-pointer">Meet the Team</h2>
          </div>
        </div>

        <div className="flex justify-center grow items-center gap-16 border-2 border-primary-100 w-full bg-white rounded-lg sm:rounded-xl shadow-xl py-6 px-8">
          <div className="cursor-pointer">
            <img src={XLogo} alt="X Logo" className="size-[60px] sm:size-[80px]" />
          </div>
          <div className="cursor-pointer">
            <img src={LinkedInLogo} alt="LinkedIn Logo" className="size-[60px] sm:size-[80px]" />
          </div>
        </div>

        <div className="flex justify-center items-center border-2 border-primary-100 w-full bg-white rounded-lg sm:rounded-xl shadow-xl py-6 px-8">
            <h3>&copy; {new Date().getFullYear()} Arcex, Inc. All rights reserved.</h3>
        </div>

      </div>

      </div>
    </div>
  );
}
