import React from "react";

//assets
import Logo from '../assets/arcexLogo.svg';
import Bell from '../assets/bell.svg';

export default function Navbar() {
  return (
    <div className="flex justify-between items-center py-4 px-4 sm:px-0 w-full sm:w-[90%] mx-auto">
        <div className="flex items-center sm:w-[145px] md:w-[160px]">
            <img src={Logo} alt='Arcex' className='size-[40px] md:size-[50px] sm:mr-2' />
            <h1 className='hidden sm:inline-block'>Arcex</h1>
        </div>
        <div className="flex items-center justify-around sm:justify-between grow max-w-[210px] md:max-w-[260px]">
            <h3 className="cursor-pointer">Investors</h3>
            <h3 className="cursor-pointer">Features</h3>
            <h3 className="cursor-pointer">Contact</h3>
        </div>
        <div className="flex items-center justify-center gap-2 bg-primary-200 border-2 border-primary-100 cursor-pointer px-2 sm:px-0 sm:w-[145px] md:w-[160px] py-2 rounded-lg sm:rounded-xl">
          <img src={Bell} alt='Notify me' className='animate-bell size-[20px]' />
          <h3 className='hidden sm:inline-block'>Get Updates</h3>
        </div>
    </div>
  );
}