import React from "react";
import { motion, useScroll, useTransform, useInView } from "framer-motion";

//icons
import Custody from "../assets/custody.svg";
import Derivatives from "../assets/derivatives.svg";
import Earn from "../assets/earn.svg";
import Commodities from "../assets/commodities.svg";
import Swaps from "../assets/swaps.svg";
import Equities from "../assets/equities.svg";

//Assets
import mockHoldings from "../assets/mockHoldings.png";
import mockSpot from "../assets/mockSpot.png";
import mockTrade from "../assets/mockTrade.png";

export default function Hero() {
  const { scrollY } = useScroll();
  const h1Opacity = useTransform(scrollY, [400, 600], [0, 1]);
  const mockOpacity = useTransform(scrollY, [300, 700], [1, 0]);

  const phoneRef = React.useRef(null);
  const mockRef1 = React.useRef(null);
  const mockRef2 = React.useRef(null);
  const mockRef3 = React.useRef(null);
  const isPhoneInView = useInView(phoneRef, { amount: 0.5 });
  const isSomePhoneInView = useInView(phoneRef, { amount: 0.3 });

  const sidebarColumnStyle = 'w-1/6 flex flex-col items-center justify-center opacity-50 md:opacity-80'
  const sidebarBlockStyle = 'flex items-center justify-center my-12 size-[50px] sm:size-[80px] md:size-[110px] lg:size-[150px] rounded-lg sm:rounded-xl border-2 border-primary-100 shadow-md sm:shadow-lg';
  const sidebarImgStyle = 'size-[32px] sm:size-[48px] md:size-[64px] lg:size-[78px] opacity-[80%]'

  return (
    <>
      <div className="flex flex-row justify-between px-4 sm:px-0 w-full sm:w-[90%] min-h-[90vh] mx-auto">
        <div className={`${sidebarColumnStyle}`}>
            <motion.div
              className={`${sidebarBlockStyle} bg-primary-300`}
              whileHover={{ scale: 1.05, rotate: -5 }}
            >
              <img src={Custody} alt="Custody" className={`${sidebarImgStyle}`}/>
            </motion.div>
            <motion.div
              className={`${sidebarBlockStyle} bg-primary-200`}
              whileHover={{ scale: 1.05, rotate: 5 }}
            >
              <img src={Derivatives} alt="Derivatives" className={`${sidebarImgStyle}`} />
            </motion.div>
            <motion.div
              className={`${sidebarBlockStyle} bg-primary-400`}
              whileHover={{ scale: 1.05, rotate: -5 }}
            >
              <img src={Earn} alt="Earn" className={`${sidebarImgStyle}`} />
            </motion.div>
            <motion.div
              className={`${sidebarBlockStyle} bg-primary-200`}
              whileHover={{ scale: 1.05, rotate: 5 }}
            >
              <img src={Derivatives} alt="Derivatives" className={`${sidebarImgStyle}`}/>
            </motion.div>
        </div>

        <div className='flex flex-col items-center mt-[40px] md:mt-[10%] lg:mt-[5%]'>
          <h1 className="text-[4rem] sm:text-[7rem] md:text-[8rem] lg:text-[10rem] font-bold justify-center text-primary-100" style={{ WebkitTextStroke: '2px black' }}>
              Arc<span className="text-primary-200">ex</span>
          </h1>
          <div className="flex justify-around items-center gap-2 mx-auto mt-[10%] sm:mt-[5%]">
            <div className="flex flex-shrink justify-center border-2 border-primary-100 cursor-pointer w-[100px] sm:w-[140px] md:w-[180px] py-2 sm:py-3 rounded-lg sm:rounded-xl shadow-lg">
              <h2 className='whitespace-nowrap'>Learn More</h2>
            </div>
            <div className="flex flex-shrink justify-center border-2 border-primary-100 cursor-pointer w-[100px] sm:w-[140px] md:w-[180px] py-2 sm:py-3 rounded-lg sm:rounded-xl shadow-lg bg-primary-200">
              <h2>Sign Up</h2>
            </div>
          </div>

          <div className='flex relative origin-center w-full sm:w-4/5 lg:w-2/3 grow mt-8 sm:mt-10 lg:mt-14 items-center justify-center'>
            
            <motion.img
              ref={mockRef1}
              src={mockSpot}
              alt="Screen"
              initial={{ filter: 'blur(0px)', opacity: 0.25 }}
              animate={{ filter: isSomePhoneInView ? 'blur(16px)' : 'blur(0px)', opacity: isSomePhoneInView ? 0 : 0.25 }}
              transition={{ duration: 0.6, ease: "easeOut" }}
              className='animate-bob1 absolute z-0 w-4/5 sm:w-2/3 left-[0px] top-[0px] opacity-25'
            />
            <motion.img
              ref={mockRef2}
              src={mockHoldings}
              style={{ opacity: mockOpacity }}
              alt="Trading View"
              transition={{ duration: 0.5, delay: 0.4, ease: "easeOut" }}
              className='animate-bob2 absolute drop-shadow-xl z-20 w-4/5 sm:w-2/3 top-[15px] sm:top-[20px] md:top-[30px]'
            />
            <motion.img
              ref={mockRef3}
              src={mockTrade}
              alt="Screen"
              initial={{ filter: 'blur(0px)', opacity: 0.25 }}
              animate={{ filter: isSomePhoneInView ? 'blur(16px)' : 'blur(0px)', opacity: isSomePhoneInView ? 0 : 0.25 }}
              transition={{ duration: 0.6, ease: "easeOut" }}
              className='animate-bob3 absolute z-10 w-4/5 sm:w-2/3 right-[0px] top-[30px] sm:top-[40px] md:top-[60px] opacity-25'
            />
          
          </div>
        </div>

        <div className={`${sidebarColumnStyle}`}>
            <motion.div
              className={`${sidebarBlockStyle} bg-primary-400`}
              whileHover={{ scale: 1.05, rotate: 5 }}
            >
              <img src={Commodities} alt="Commodities" className={`${sidebarImgStyle}`} />
            </motion.div>
            <motion.div
              className={`${sidebarBlockStyle} bg-primary-300`}
              whileHover={{ scale: 1.05, rotate: -5 }}
            >
              <img src={Swaps} alt="Swaps" className={`${sidebarImgStyle}`} />
            </motion.div>
            <motion.div
              className={`${sidebarBlockStyle} bg-primary-200`}
              whileHover={{ scale: 1.05, rotate: 5 }}
            >
              <img src={Equities} alt="Equities" className={`${sidebarImgStyle}`} />
            </motion.div>
            <motion.div
              className={`${sidebarBlockStyle} bg-primary-300`}
              whileHover={{ scale: 1.05, rotate: -5 }}
            >
              <img src={Earn} alt="Earn" className={`${sidebarImgStyle}`} />
            </motion.div>
        </div>
      </div>

      <div className="bg-primary-200 min-h-[100vh] p-4 sm:p-[5%] flex flex-col justify-center sm:flex-row-reverse">

        <div className="mx-auto sm:mx-0 w-full sm:w-[33%] flex flex-col justify-center items-center">
          <motion.h1
            className="font-bold text-3xl md:text-4xl text-center"
            style={{ opacity: h1Opacity }}
            transition={{ type: "spring", stiffness: 50, damping: 10 }}
          >
            In today's world,<br />everything should be simple
          </motion.h1>
        </div>

        <div className='flex items-center w-1/2 mx-auto py-4 sm:px-4 justify-center'>
          <motion.img
            ref={phoneRef}
            src={mockTrade}
            alt="Trading View"
            initial={{ y: -70, opacity: 0 }}
            animate={{ 
              y: isPhoneInView ? 0 : -70, 
              opacity: isPhoneInView ? 1 : 0 
            }}
            transition={{ duration: 0.5, delay: 0.4, ease: "easeOut" }}
            className='relative drop-shadow-xl max-h-[680px]'
          />
        </div>

        <div className="w-full sm:w-[33%] flex flex-row sm:flex-col flex-wrap sm:flex-nowrap items-center justify-around sm:justify-center">
          {[
            [Swaps, 'Custody & Swap'],
            [Equities, 'Spot & Derivatives'],
            [Commodities, 'Equities & Commodities'],
            [Earn, 'Lend & Earn'],
          ].map(([image, label]) => (
            <div key={label} className="flex items-center justify-center w-[48%] sm:w-full max-w-[210px] sm:max-w-[320px] my-2">

              <div className="flex justify-center items-center flex-shrink-0 size-[50px] sm:size-[80px] md:size-[100px] mr-[-30px] sm:mr-[-40px] md:mr-[-50px] z-10 bg-white rounded-full border-2 border-primary-100">
                <img src={image} className="size-[30px] sm:size-[40px] md:size-[50px]" alt="Swaps" />
              </div>

              <div className="flex items-center grow border-2 border-primary-100 bg-white rounded-lg sm:rounded-xl shadow-lg pl-[32px] sm:pl-[40px] md:pl-[60px] h-[40px] sm:h-[70px] md:h-[90px]">
                <h1 className='font-[500] lg:font-bold text-[1rem] sm:text-[1.1rem] md:text-[1.4rem] leading-[1rem] sm:leading-[1.6rem] md:leading-[2rem]'>{label}</h1>
              </div>
            </div>
          ))}
        </div>
        
      </div>
    </>
  );
}
